import React, { useState,useEffect } from "react";
import {useNavigate,  Link } from "react-router-dom";
import axios from "axios";
import { ModelList } from "../common/constants";
import { CiEdit } from "react-icons/ci";

function Models() {
 
 
  const navigate = useNavigate();
 
  const [modelsData,setModels] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await  axios.post(ModelList,{ }, {
      headers: {
        'token': localStorage.getItem('token'), // Example authorization header
        'Content-Type': 'application/json', // Example content type header
        // Add more headers as needed
      }
      })
    .then(result => {
      // Handle response
      setModels(result.data.data);
    
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
    
    };
    fetchData();
  }, []); // No dependencies, this runs only on mount and unmount

  
 
  

  




  return (
    <div>
      <div className="flex justify-between items-center"> 
      <div> 
      <h2>Plumbers</h2>
      <div className="breadcrumbs">
        {" "}
        <Link to="/admin/dashboard">Home</Link>
        <span>{">"} </span>Models{" "}
      </div>
      </div>
      
      <div>
      <Link to="/admin/add-model" > <button    className='genreport-btn'>Add Model</button></Link>
      </div>
      </div>
      <div className="whitebox">
        <div>
          <table className="tablegrid" width={"100%"}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
              
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {modelsData.map((item, index) => (
                <tr key={index}>
                  <td>{index+1}</td>
                  <td className="redfont">{item.model}</td>
                 
                  <td><CiEdit /></td>
           
                </tr>
              ))}
            </tbody>
          </table>

     
        </div>
      </div>


  
    </div>
  );
}

export default Models;
