import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from '../../services/common/user.service';

const LocationComponent = ({ onClose, closeLocation, onlocationClose }) => {
  const userServiceInstance = new UserService();
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    userLocation: '',
  });
  const navigate = useNavigate();

  const findCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            userLocation: `Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`,
          });
          try {
            const payload = { "latitude": position.coords.latitude, "longitude": position.coords.longitude }
            const locationData = await userServiceInstance.locationDetails(payload).then(response => response?.data?.data).catch(error => console.log(error))
            if (closeLocation) {
              onlocationClose(locationData?.address);
            } else {
              navigate('/user/personaldetails', { replace: true, state: { locationData } });
            }
          } catch (error) {
            console.error("Error fetching location details:", error);
          }
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };


  return (
    <div>
      <div className='flex justify-center'> <img src='./../images/location.png' alt='location' />  </div>
      <h1 className='flex justify-center mt-4'>Location Permission</h1>
      <p className='flex justify-center mb-4'>Need Location permission to continue</p>

      {location.userLocation ? (
        <p className='flex justify-center'>{location.userLocation}</p>
      ) : (
        null
      )}
      <div className='mt-4 text-center'>
        <button className='redbtn' style={{ width: "60%" }} onClick={findCoordinates}>Enable</button>
      </div>
      <div className='mt-4 text-center'>
        <button className='btntran' onClick={onClose}>Not now</button>
      </div>
    </div>
  );
};

export default LocationComponent;
