import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SlMenu } from "react-icons/sl";
import { GrClose } from "react-icons/gr";
import { FaRegBell } from "react-icons/fa"; // Ensure the correct import for your icons
import { useAuth } from '../../provider/authProvider';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false); 

  // Use the useAuth hook to access logout function
  const { logout } = useAuth();
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    setToken(); // Use logout function from auth provider
    navigate('/admin/login',{ replace: true }); 
  };

  return (
    <>
      <header>
        <div className='container' style={{margin:"0px auto"}}> 
        <div className='flex justify-between items-center'>
          <div><img src='../images/dilips-logo.png' style={{height:"55px"}} alt='logo' /></div>
          <div className='flex gap-3'>           
            <div><FaRegBell /></div>
            <div className='relative'>
              {menuOpen
                ? <GrClose className='cursor-pointer' onClick={toggleMenu} />
                : <SlMenu className='cursor-pointer' onClick={toggleMenu} />
              }
              {menuOpen && (
                <ul className='headermenu'>
                
                  <li><NavLink to="/admin/dashboard" activeClassName="active">Dashboard</NavLink></li>
                  <li><NavLink to="/admin/models" activeClassName="active">Models List</NavLink></li>
                  <li><NavLink to="/admin/products" activeClassName="active">Products List</NavLink></li>
                  <li><NavLink to="/admin/addproducts" activeClassName="active">Add Products</NavLink></li>
                  <li><NavLink to="/admin/plumbers" activeClassName="active">Plumbers</NavLink></li>
                  <li><NavLink to="/admin/customers" activeClassName="active">Customers</NavLink></li>
                  <li><NavLink to="/admin/qrlist" activeClassName="active">QR List</NavLink></li>
                  <li><NavLink to="/admin/qrgenerate" activeClassName="active">Generate QR</NavLink></li>
                  <li><NavLink to="/admin/reports" activeClassName="active">Reports</NavLink></li>
                  <li className='cursor-pointer' onClick={handleLogout}> Logout</li>
                </ul>
              )}
            </div>
          </div>
        </div>
        </div>
      </header>
    </>
  );
}

export default Header;
