import React,{useState} from 'react'
import Popover from  './Popover'  
import axios from "axios";
import { APPROVE_REDEEM_POINTS } from '../../common/constants';

const Preview = ({reward_points,userData,popOverClose}) =>{
        
  const [selectedPopoverId, setSelectedPopoverId] = useState(false);

  const handleOpenPopover = () => {

   
    axios.post(APPROVE_REDEEM_POINTS,{'user_id':userData.user_id}, {
      headers: {
        'token': localStorage.getItem('token'), // Example authorization header
        'Content-Type': 'application/json', // Example content type header
        // Add more headers as needed
      }
      })
  .then(response => {
    // Handle response
    setSelectedPopoverId(true);
   
   
  })
  .catch(error => {
    // Handle error
    console.error('Error:', error);
  });
    
  };

  const handleClosePopover = () => {
    setSelectedPopoverId(false);
    popOverClose();
  };
  return (
    <>
     <h2 style={{margin:"-20px 20px 10px 0px"}}>Rewards Preview</h2>
    <h4 className='mb-6'>{userData.user_name}</h4>
    <ul className='rewardlist'>

        <li><label>Rewards Granted</label> <label className='redfont'>{reward_points.reward_granted}</label></li>
        <li><label>Total Reward Points</label> <label  className='redfont'>{reward_points.total_reward_points}</label></li>
        <li><label>Redeemed Rewards</label> <label  className='redfont'>{reward_points.rewards_points_reedemed}</label></li>
        <li><label>Balance Rewards</label> <label  className='redfont'>{reward_points.balance_reward_points}</label></li>
        <li className='bg-black text-white '><label style={{color:"#fff"}}>Rewards for approval</label> <label  className='redfont'>{reward_points.Rewards_points_for_Approval}</label></li>
    </ul>
    <div className='flex justify-center mt-6 mb-4'>
        {reward_points.Rewards_points_for_Approval > 0 && (
        <button className='genreport-btn' style={{padding:"5px 10px", height:"auto", fontSize:"14px"}} onClick={() => handleOpenPopover()}>
          Approve Rewards
        </button>
      )}   
   </div>

        {selectedPopoverId && (
              <Popover onClose={handleClosePopover} isVisible={selectedPopoverId !== null}>
              
               
    <div className="flex  justify-center okicon"> 
                <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
      <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" stroke-width="1"/>
      <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" stroke-width="2"/>

        {/* <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/> */}
      
      </g>
    </svg>
    </div>  
    <div className='flex justify-center items-center text-center' style={{flexDirection:"column"}}> 
    <div  className='redfont text-2xl font-bold'> {reward_points.Rewards_points_for_Approval}</div>    
    <h4> Rewards  Approved <br/> 
    Successfully</h4>
    </div> 
              </Popover>
            )}

    </>
  )
}

export default Preview