import React, { createContext, useContext, useEffect, useState,useMemo } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem('token'));

  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["token"] = token;
      localStorage.setItem('token',token);
    } else {
      delete axios.defaults.headers.common["token"];
      localStorage.removeItem('token')
    }
  }, [token]);
 // Memoized value of the authentication context
 const contextValue = useMemo(
  () => ({
    token,
    setToken,
  }),
  [token]
);
  const value = { token, setToken, isLoading };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;



