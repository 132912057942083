import React,{useState,useEffect} from 'react'
import { Link, useParams  } from 'react-router-dom';
import styles from './Qr-module.css';
import axios from "axios";
import config from "../config";
import { GENERATED_QRS, PRINT_PRODUCT_QR } from '../common/constants';

function PrintQr() { 
 
    const { qr_list_id }  =  useParams();

    const [qrImages,setQrImages] = useState([]);
    useEffect(() => {
      // Fetch data from the API
    
      getQrs();
      
    
    
    }, []); // Empty dependency array means this effect runs only once on component mount

    const getQrs=()=>{

      
        axios.post(PRINT_PRODUCT_QR,{'qr_list_id' : qr_list_id}, {
          headers: {
            'token': localStorage.getItem('token'), // Example authorization header
            'Content-Type': 'application/json', // Example content type header
            // Add more headers as needed
          }
          })
        .then(response => {
          

            console.log(response.data.data);
       
            setQrImages(response.data.data);
        
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
        });
    }
 
    useEffect(() => {
        // Class to add
        const bodyClass = 'qrprintcom'; 
        document.body.classList.add(bodyClass); 
        return () => {
          document.body.classList.remove(bodyClass);
        };
      }, []);  

  return (
    <div className='qrcodeslist'> 
          { qrImages ?  (
              qrImages.map(image => (
                <div key={image.id}  className="flex justify-between items-center p-5">
                 
                        <img style={{width:"200px"}} src={image.imageUrl} alt="description" />
                     
                     
                  
                </div>
            ))) : (<div>Loading Data</div>)}
 
    </div>
  )
}

export default PrintQr