import React, { useEffect, useState } from 'react'
import { SlMenu } from "react-icons/sl";
import { FaRegBell } from "react-icons/fa6";
import { NavLink, useNavigate } from 'react-router-dom';
import { GrClose } from "react-icons/gr";
import UserService from '../../services/common/user.service';

function RewardsHeader({ onPointsClick, render, getRedeemPoints }) {
  const userServiceInstance = new UserService();
  const [menuOpen, setMenuOpen] = useState(false);
  const [rewards, setRewards] = useState(null);
  const navigate = useNavigate();
  const gotoHome = () => {
    navigate('/user/rewards');
  }
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const showReedem = () => {
    navigate("/user/rewards", { state: { reedem: true } });
  }

  useEffect(() => {
    userServiceInstance.rewardPoints().then(response => {
      if (response.data.statusCode === 200) {
        setRewards(response?.data?.data);
        if (typeof getRedeemPoints === 'function') {
          getRedeemPoints(response?.data?.data?.balance_reward_points);
        }
      }
    }).catch(error => console.log(error))
  }, [render, getRedeemPoints])

  return (
    <div className='rewards-logo-header'>

      <div className='headerlogo cursor-pointer'  >
        <img src='../images/user-logo.png' alt='logo' /></div>
      <div >
        <div className='flex gap-4 justify-end'>
          <FaRegBell style={{ color: "#fff", fontSize: "18px" }} className='cursor-pointer' />
          {
            menuOpen
              ? <GrClose style={{ color: "#fff", fontSize: "18px" }} className='cursor-pointer' onClick={toggleMenu} />
              : <SlMenu style={{ color: "#fff", fontSize: "18px" }} className='cursor-pointer' onClick={toggleMenu} />
          }
        </div>
        {menuOpen && (
          <div className={`headermenu headermenuuser ${menuOpen ? 'slide-in' : 'slide-out'}`} style={{ width: "100%", paddingTop: "10px", top: "0px", height: "100vh" }}>
            <div className='flex justify-between it'>
              <div className='headerlogo cursor-pointer' onClick={gotoHome}>
                <img src='../images/logo_gray.png' alt='logo' /></div>

              <div className='flex gap-4 justify-end'>
                <FaRegBell style={{ fontSize: "18px" }} className='cursor-pointer redfont' onClick={toggleMenu} />
                {
                  menuOpen
                    ? <GrClose className='cursor-pointer redfont' onClick={toggleMenu} />
                    : <SlMenu className='cursor-pointer redfont' onClick={toggleMenu} />
                }
              </div>
            </div>

            <ul style={{ width: "100%", paddingTop: "10px", }} className='userheaderlist'>

              <li><NavLink to="/user/personaldetails" activeClassName="active">Personal Details</NavLink></li>
              <li><NavLink onClick={() => { if (typeof onPointsClick === 'function') { onPointsClick(); } showReedem(); toggleMenu(); }} to="/user/rewards" activeClassName="active">Redeem Your Points</NavLink></li>
              <li><NavLink to="/user/scanqr" activeClassName="active">Scan QR</NavLink></li>
              <li><NavLink to="/user/points" activeClassName="active">Rewards History</NavLink></li>
              <li><NavLink to="" activeClassName="active">Nearby Stores</NavLink></li>
              {/* <li><NavLink to="" onClick={() => { localStorage.clear(); navigate("/user/login", { replace: true }); }} activeClassName="active">Logout</NavLink></li> */}

            </ul>
          </div>
        )}
        <div className='text-right mt-6'>
          <h1 className='font-bold whitefont'>{rewards?.balance_reward_points}</h1>
          <p className='whitefont'>Reward Points Balance</p>
        </div>
      </div>
    </div>
  )
}

export default RewardsHeader