// const url ="http://192.10.250.112:5001";
const url = "https://dilipsindiaapi.stellifyflows.in"

const API_ENDPOINTS = {
    TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGUiOiI4MDE5NDI1MTE5Iiwicm9sZV9pZCI6MSwiaWF0IjoxNzEwMzA2ODk1fQ.cZ0XeQdp8_-U3Zlq5KS34j_GHwNt72hVZIjayswEpuE",
    GENERATE_OTP: `${url}/users/generate_Otp`,
    VALIDATE_OTP: `${url}/users/validate_Otp`,
    PRODUCTS_LIST: `${url}/users/products`,
    MODELS_LIST: `${url}/users/models`,
    MODEL_PRODUCTS: `${url}/users/product/model`,
    PRODUCTS_STORE: `${url}/users/products/store`,
    REGISTER: `${url}/users/registeration`,
    PROFILE_UPDATE: `${url}/users/user-update`,
    LOCATION: `${url}/users/maps`,
    USER_DETAILS: `${url}/users/getUserDetails`,
    SCAN: `${url}/users/scanner/store`,
    PRODUCT_DETAILS: `${url}/users/products/get`,
    REWARD_POINTS: `${url}/users/scanners/get`,
    REWARDS_HISTORY: `${url}/users/scannersById`,
    REDEEM_POINTS: `${url}/users/reedemed/store`,
    GET_REDEEMED_POINTS_BY_TRANSACTION_ID: `${url}/admin/get_Items_By_Transaction_id`,
    APPROVE_REDEEM_POINTS :`${url}/admin/approval/store`,
    USER_REWARD_POINTS : `${url}/admin/scanners/get`,
    PRINT_PRODUCT_QR : `${url}/admin/qr-print`,
    GENERATED_QRS : `${url}/admin/get_generated_qr_products`,
    DASHBOARD:`${url}/admin/dashboard`,
    ModelList:`${url}/admin/models`,
    StoreModel:`${url}/admin/model/store`,
    getModel:`${url}/admin/model/store`,
    BulkUpload:`${url}/admin/products/bulkUpload`
};

module.exports = API_ENDPOINTS;