import React, { useState,useEffect } from "react";
import {  Link } from "react-router-dom";
import axios from "axios";
import config from "../config";
import { useNavigate } from "react-router-dom";
import Popover from '../admin/shared/Popover';
import SucessMessage from "./shared/SucessMessage";

function QrGenerate() {

  const navigate = useNavigate();
  const initialFormState = {
    product_id: '',
    modal: '',
    reward_points: '',
    quantity: ''
  };
const [form, setForm] = useState(initialFormState);
const [isQrGenerated,setQrGenerated] = useState(false);
const [errors, setErrors] = useState({});
  const [models, setModelData] = useState([]);
  const [selectedModel,setSelectedModel] = useState('');
  const [products,sestProductsData]=useState([]);
  useEffect(() => {
    // Fetch data from the API
  
    axios.post(`${config.backendUrl}/admin/models`,{}, {
      headers: {
        'token': localStorage.getItem('token'), // Example authorization header
        'Content-Type': 'application/json', // Example content type header
        // Add more headers as needed
      }
      })
    .then(response => {
      // Handle response
      setModelData(response.data.data);

      console.log(models);
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
  
  
  }, []); // Empty dependency array means this effect runs only once on component mount
  useEffect(() => {
    if (form.modal) {
      axios.post(`${config.backendUrl}/admin/product/model`,{"modal":form.modal}, {
        headers: {
          'token': localStorage.getItem('token'), // Example authorization header
          'Content-Type': 'application/json', // Example content type header
          // Add more headers as needed
        }
        })
      .then(response => {
        // Handle response
        sestProductsData(response.data.data);
     
        console.log(response.data.data);
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });
    }
  }, [form.modal]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm({
          ...form,
          [name]: value
        });
        // Clear the error message when user starts typing again
        setErrors({
          ...errors,
          [name]: ''
        });
      };
// Expanded validation logic
const validateForm = () => {
  let newErrors = {};
  let isValid = true;

  // Example validation for each field
  if (!form.modal){
    newErrors.modal = "Please select a model.";
    isValid = false;
  }
  if (!form.product_id) {
    newErrors.product_id = "Please select a product.";
    isValid = false;
  }
  if (!form.reward_points) {
    newErrors.reward_points = "Please enter reward points.";
    isValid = false;
  } else if (!/^\d+$/.test(form.reward_points)) { // Ensure reward_points is a positive integer
    newErrors.reward_points = "Reward points must be an integer.";
    isValid = false;
  }
  if (!form.quantity) {
    newErrors.quantity = "Please enter quantity.";
    isValid = false;
  } else if (!/^\d+$/.test(form.quantity)) { // Ensure reward_points is a positive integer
    newErrors.quantity = "Quantity must be an integer.";
    isValid = false;
  }
  // Add validation for other fields as needed

  setErrors(newErrors);
  return isValid;
};

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      

    // Post data to API
    try {
        await axios.post(`${config.backendUrl}/admin/qr-generator`, form, {
          headers: {
            'token': localStorage.getItem('token'), // Example authorization header
            'Content-Type': 'application/json', // Example content type header
            // Add more headers as needed
          }
          });
        setQrGenerated(true);

      
      } catch (error) {
        // Handle error
        console.error('Error uploading file:', error);
      }
    }

    
  };
  const handleClosePopover = () => {
    
    navigate('/admin/qrlist'); 
    setQrGenerated(false);
  };
return (
  <>
<div>
<div> 
<h2>Generate  QR</h2>
<div className='breadcrumbs'> <Link to="/admin/dashboard">Home</Link>{">"} Generate  QR </div>
</div>

<div className="whitebox">
<form onSubmit={handleFormSubmit} className="w-full max-w-lg " >
{/* Sub-Product Name Select Box */}
<div className="flex items-center mb-5">
<label htmlFor="modal" className="block text-sm font-medium leading-5  w-1/3 mr-4">
Model
</label>
<div className="block w-2/3 pl-3 pr-10 py-2 w-full">
<select 
id="modal"
value={form.modal}
name="modal" onChange={handleChange}
className="w-full  text-base leading-6 border border-gray-300    sm:text-sm sm:leading-5 rounded-md shadow-sm"
>
<option value="">Select a model</option>
          {models.map((model, index) => (
            <option key={index} value={model.id}>{model.model}</option>
          ))}
</select>

{errors.modal && <div className="redfont">{errors.modal}</div>}
</div>

</div>
 
<div className="flex items-center mb-5">
<label htmlFor="product-name" className="block text-sm font-medium leading-5  w-1/3 mr-4">
Product Name
</label>
<div className="block w-2/3 pl-3 pr-10 py-2 w-full"> 
<select 
id="product-name"
placeholder="enter product name" 
name="product_id"   
value={form.product_id} onChange={handleChange}

className="w-full pl-3 pr-10  text-base leading-6 border border-gray-300   sm:text-sm sm:leading-5 rounded-md shadow-sm"
>
<option key="0" value=""> -- Select Product --</option>
{products.map((product, index) => (
            <option key={index+1} value={product.id}>{product.name}</option>
          ))}
</select>

{errors.product_id && <div className="redfont">{errors.product_id}</div>}
</div>
</div>



{/* Add Rewards Input Field */}
<div className="flex items-center mb-5">
<label htmlFor="add-rewards" className="block text-sm font-medium leading-5  w-1/3 mr-4">
Add Rewards
</label>
<div className="block w-2/3 pl-3 pr-10 py-2 w-full">
<input 
type="text" 
name="reward_points"
id="add-rewards" 
placeholder="Add Rewards"
value={form.reward_points} onChange={handleChange}

className="appearance-none w-full block w-2/3 px-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  transition duration-150 ease-in-out sm:text-sm sm:leading-5"
/>
{errors.reward_points && <div className="redfont">{errors.reward_points}</div>}
</div>


</div>

 
<div className="flex items-center mb-6">
<label htmlFor="add-quantity" className="block text-sm font-medium leading-5  w-1/3 mr-4">
Add Quantity
</label>
<div className="block w-2/3 pl-3 pr-10   w-full">
<input 
type="text"
id="add-quantity" 
placeholder="Add Quantity"
value={form.quantity} onChange={handleChange}
name="quantity"

className="appearance-none w-full block w-2/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
/>
{errors.quantity && <div className="redfont">{errors.quantity}</div>}
</div>
</div>

 
<div className="flex items-center  ">
<label htmlFor="add-quantity" className="block text-sm font-medium leading-5  w-1/3 mr-4">

</label>
<div className="block w-2/3 pl-3 pr-10   w-full">
<button type="submit" className="redbtn ">
Generate QR
</button>
</div>
</div>

</form>
</div>

</div>
{isQrGenerated && (

          <Popover  onClose={handleClosePopover} isVisible={isQrGenerated !== null}>
          
                    
             <SucessMessage >

                  <h4>QR Generated  <br/> 
                            Successfully</h4>
             </SucessMessage>

          </Popover>
        )}
</>
)
}

export default QrGenerate