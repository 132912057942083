import React,{useState} from 'react'
import Popover from  './Popover'    
const SucessMessage = ({children}) =>{
        
 


  return (
    <>
    
 


     
<div className="flex  justify-center okicon"> 
            <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
  <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
  <path className="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" stroke-width="1"/>
  <path className="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" stroke-width="2"/>

    {/* <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/> */}
   
  </g>
</svg>
</div>  
<div className='flex justify-center items-center text-center' style={{flexDirection:"column"}}> 
 
{children}
   
</div> 
         

    </>
  )
}

export default SucessMessage