import React from 'react'

function CategoryBox({children}) {
  return (
    <div className='usercategories-box'>
   {children}
    </div>
  )
}

export default CategoryBox