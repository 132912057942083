import React,{useState,useEffect} from 'react';
import { Link  } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { useNavigate } from "react-router-dom";

function EditProduct() {

  const [models, setModelData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch data from the API
  
    axios.post(`${config.backendUrl}/admin/models`)
    .then(response => {
      // Handle response
      setModelData(response.data.data);

      console.log(models);
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
  
  
  }, []); // Empty dependency array means this effect runs only once on component mount
  const [formData,setFormData] = useState({
    name:"",
    modal:"",
    image:null
  });
  const [errors,setErrors] = useState(
    {productName: '',
    modelName: '',
    image: '',});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = 5 * 1024 * 1024; // 5MB

    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setErrors({
        ...errors,
        image: 'File size exceeds 5MB limit',
      });
    } else {
      setFormData({
        ...formData,
        image: selectedFile,
      });
      setErrors({
        ...errors,
        image: '',
      });
    }
  };

  const handleSubmit = async (event) => {
   
    
    event.preventDefault();
    if (!formData.image) {
      setErrors({
        ...errors,
        image: 'Please select a file',
      });
      return;
    }
    if (!formData.name) {
      setErrors({
        ...errors,
        name: 'Please enter a product name',
      });
      return;
    }
    if (!formData.modal) {
      setErrors({
        ...errors,
        modal: 'Please enter a model name',
      });
      return;
    }
    
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('modal', formData.modal);
    formDataToSend.append('image', formData.image);

    try {
      await axios.post(`${config.backendUrl}/admin/products/store`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // Handle success
      navigate('/admin/products'); 
      console.log('File uploaded successfully');
    } catch (error) {
      // Handle error
      console.error('Error uploading file:', error);
    }
    console.log('Form Data:', formData);
    // Clear form fields and file input
    setFormData({
      name: '',
      modal: '',
      image: null,
    });
    setErrors({
      name: '',
      modal: '',
      image: '',
    });
  };




  return (
    <> 
    <div> 
      <h2>Products</h2>
      <div className="breadcrumbs">
        {" "}
        <Link to="/admin/dashboard">Home</Link>
        <span>{">"} </span>Add Products{" "}
      </div>
      </div>

      <div className='whitebox uploadproductpop' >
      <div className="container mx-auto p-4">
      <div className="flex flexwrapcol -mx-2 lg:gap-10">
       
        {/* Column 1 */}
       
        <div className="w-full md:w-1/2 px-2 mb-4"> 
        <h2>Add Product</h2>
        <p className='mb-3'>Add to Create Product</p>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mb-6">
          <label className="block text-sm   leading-5  w-1/2" htmlFor="productName">
            Product Name
          </label>
         <div className="block w-full "> <input name="name"  value={formData.name}  onChange={handleInputChange} className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline" />
          {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
          </div>

          </div>
        
          <div className="flex items-center mb-6">
          <label className="block text-sm   leading-5  w-1/2"  htmlFor="modalName">
            Model
          </label>
         <div className="block w-full">
          <select name="modal"  value={formData.modal}   onChange={handleInputChange} className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline" >
          <option value="">Select a model</option>
          {models.map((model, index) => (
            <option key={index} value={model.id}>{model.model}</option>
          ))}
          </select>
             
          {errors.modal && <p style={{ color: 'red' }}>{errors.modal}</p>}</div> 

          </div>
          <div className="flex items-center mb-6">
        <label className="block text-sm   leading-5 w-1/2">Add Image</label>
        <div className="block relative w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
          {/* Your input to accept files */}
          <input
            type="file" name="image"
            onChange={handleFileChange} 
            multiple
            accept=".jpg,.jpeg,.png" // Adjust accordingly for image files
            style={{ opacity: "0", position:"absolute",width:"100%" }} // Hide the default file input
            id="file-upload" // Id for the label
          />
          
          {/* Label to trigger the hidden file input */}
          <label htmlFor="file-upload" className="flex justify-between items-center">
         <div> Upload Image(s) </div>  <button style={{height:"20px", width:"100px"}} className='redbtn'> Browse</button>
          </label>
        
          {/* List of uploaded images */}
        
        </div>
    
          </div>
          {errors.image && <p style={{ color: 'red' }}>{errors.image}</p>}
          <div className="flex items-center mb-6">
            <label className="block text-sm   leading-5  w-1/2 "> 
            </label>
            <div  className="block w-full"> 
            <button className="redbtn" type="submit">
              Update Product
            </button>
          </div>
          </div>
          </form>
        </div>
     
      
      </div>
    </div>
      </div>
    
    </ >
  )
}

export default EditProduct