// Popover.js
import React from 'react';

const Popover = ({ onClose, children, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="popover-container">
      <div className="popover-backdrop" onClick={onClose} />
      <div className="popover-content">
      <div className="flex justify-end"><button onClick={onClose} className="close-btn">X</button></div>
      
        {children}
       
      </div>
    </div>
  );
};

export default Popover;
