import React, { useEffect, useState } from 'react'
import RewardsHeader from './shared/RewardsHeader'
import { useNavigate } from 'react-router-dom';
import UserService from '../services/common/user.service';

function RewardPoints() {
  const userServiceInstance = new UserService();
  const [points, setPoints] = useState(null);
  const navigate = useNavigate(); 
  
  const gotoRewards = () => {
    navigate('/user/rewards', { state: { reedem: true } });
  }

  const gotoRewardsHistory = () => {
    navigate('/user/rewardshistory');
  }

  useEffect(() => {
    userServiceInstance.rewardPoints().then(response => {
      if (response.data.statusCode === 200) {
        setPoints(response.data.data);
      }
    }).catch(error => console.log(error))
  }, [])

  return (
    < >
      <RewardsHeader />
      <div className="form-wrapper" style={{ marginTop: "-85px" }}>
        <h4 className="whitefont mb-2">My Reward Points </h4>
        <ul className='userrewardlist'>
          <li><label>Total Reward Points</label> <label>{points?.total_reward_points}</label></li>
          <li><label>Reward Points redeemed </label><label>{points?.rewards_points_reedemed}</label></li>
          <li><label>Balance reward points </label><label>{points?.balance_reward_points}</label></li>
          <li><label>Reward points for approval</label> <label>{points?.Rewards_points_for_Approval}</label></li>
        </ul>

        <div className='flex gap-3 justify-center mt-10'>
          <button onClick={gotoRewards} className='redbtn'>Claim Rewards</button>
          <button onClick={gotoRewardsHistory} className='redbtn'>View History</button>
        </div>

      </div>
    </ >
  )
}

export default RewardPoints