import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import QrReader from 'react-qr-reader';
import PopOver from '../user/shared/Popover';
import CusPopOver from './shared/CusPopOver';
import LocationComponent from './shared/LocationComponent';

function ScanQr() {
  const navigate = useNavigate();
  const [isCameraOn, setIsCameraOn] = useState(false);
  const qrRef = useRef(null);
  const [selectedPopoverId, setSelectedPopoverId] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [cameraPermission, setCameraPermission] = useState('pending');

  const closeScanQr = () => {
    setIsCameraOn(false);
    navigate('/user/rewards');
  };

  const gotoclaimRewards = (scannedData) => {
    navigate('/user/claimrewards', { replace: true, state: { scannedData, locationData } });
  };

  const handleScan = async (scannedData) => {
    if (scannedData) {
      gotoclaimRewards(scannedData);
      setIsCameraOn(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setCameraPermission('denied');
  };

  const handleClosePopover = () => {
    navigate('/user/rewards', { replace: true });
    setSelectedPopoverId(null);
  };

  const handlemapClose = (locData) => {
    setLocationData(locData);
    setSelectedPopoverId(null);
    setIsCameraOn(true);
  };

  const checkCameraPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'camera' });
      setCameraPermission(permissionStatus.state);
      permissionStatus.onchange = () => {
        setCameraPermission(permissionStatus.state);
      };
    } catch (error) {
      console.error('Error checking camera permission:', error);
      setCameraPermission('denied');
    }
  };


  useEffect(() => {
    checkCameraPermission();
    setIsCameraOn(false);
    setSelectedPopoverId(true);
    return () => { setSelectedPopoverId(false); };
  }, []);

  return (
    <>
      {cameraPermission === 'denied' ? (
        <div className="text-center">
          <p>Camera access is required to scan QR codes. Please enable camera permissions in your browser settings.</p>
          <button onClick={() => checkCameraPermission()} className="btn-primary">Check Permission Again</button>
        </div>
      ) : (
        <PopOver>
          <div className='text-center'>
            <div><IoClose className="cursor-pointer" style={{ fontSize: "25px", fontWeight: "bold" }} onClick={closeScanQr} /></div>
            <div className='flex justify-center mt-6'>
              {isCameraOn && (<QrReader ref={qrRef}
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%' }}
              />)}
            </div>
            <h1 className='whitefont mt-4'>Scan QR Code</h1>
          </div>
        </PopOver>
      )}

      {selectedPopoverId && (
        <CusPopOver onClose={handleClosePopover} isVisible={selectedPopoverId !== null}>
          <LocationComponent onClose={handleClosePopover} onlocationClose={handlemapClose} closeLocation={true}></LocationComponent>
        </CusPopOver>
      )}
    </>
  );
}

export default ScanQr;
