import React, { useEffect, useRef, useState } from 'react';
import UserHeader from './shared/UserHeader';
import FormBox from './shared/FormBox';
import { useLocation, useNavigate } from 'react-router-dom';
import UserUpdateHeader from './shared/UserUpdateHeader';
import UserService from '../services/common/user.service';
import toast, { Toaster } from 'react-hot-toast';

function PersonalDetails() {
  const userServiceInstance = new UserService();
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { address } = location?.state?.locationData || {};
  const addressRef = useRef("");
  const [formData, setFormData] = useState({ name: "", address: "", mobile: "" });
  const [formErrors, setFormErrors] = useState({ name: '' });
  const [btn, setBtn] = useState("Submit");
  const userAddress = address && address?.neighbourhood + " " + address?.county + " " + address?.city + " " + address?.state + " " + address?.postcode;

  const isFormValid = () => {
    const errors = {};
    if (!formData?.name?.trim()) errors.name = 'Name is required.';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isFormValid()) {
      return;
    } else {
      const palyload = { "location": userAddress, "name": formData.name, "address": addressRef.current.value }
      userServiceInstance.userDetailsUpdate(palyload).then(response => {
        if (response.data.statusCode === 201) {
          toast.success('Account Updated Successfully', {duration:1000});
          setTimeout(() => {
            toast.dismiss();
            navigate('/user/rewards', { replace: true });
          }, 2500);
        }
      }).catch(error => console.log(error))
    }
  }


  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setFormErrors(prev => ({ ...prev, [name]: '' }));
  };

  useEffect(() => {
    userServiceInstance.userDetails().then(response => {
      if (response.data.statusCode === 200) {
        const data = response?.data?.data;
        setFormData({
          name: data.name || "",
          address: data.address || "",
          mobile: data.mobile || ""
        });
        data?.name === null ? setBtn("Submit") : setBtn("Update Details");
        setIsValid(data?.name?.trim() !== '');
      }
    }).catch(error => console.log(error));

    return () =>  toast.dismiss() 
  }, []);

  return (
    <>
      <Toaster />
      {btn === "Submit" ? <UserHeader /> : <UserUpdateHeader />}
      <FormBox>
        <h1>Personal Details</h1>
        <p>Please enter your personal details</p>

        <form onSubmit={handleSubmit}>
          <div className="input-group mt-4">
            <label>Name <span className='redfont'>*</span></label>
            <input
              type="text"
              className={`form-control ${!isValid ? 'invalid-input' : ''}`}
              placeholder="Your name"
              value={formData.name}
              name="name"
              onChange={onChangeHandler}
            />
            {formErrors.name && <div className="error text-red-500">{formErrors.name}</div>}
          </div>

          {/* {btn !== "Submit" ? ( */}
          <div className="input-group mt-4">
            <label>Mobile Number</label>
            <input
              type="text"
              className={`form-control`}
              placeholder="Mobile Number"
              value={formData.mobile}
              disabled
              onChange={onChangeHandler}
            />
          </div>
          {/* ) : (<></>)} */}


          <div className='input-group mt-4'>
            <label>Address</label>
            <textarea
              ref={addressRef}
              style={{ height: "100px" }}
              className="form-control text-black"
              placeholder="Your address"
              name="address"
              value={formData.address !== "" ? formData.address : userAddress || ""}
              onChange={onChangeHandler}
            />
          </div>

          <div className='flex justify-center mt-4'>
            <button type="submit" className={`redbtn ${!isFormValid ? 'disabled' : ''}`} disabled={!isFormValid}>{btn}</button>
          </div>
        </form>
      </FormBox>
    </>
  );
}

export default PersonalDetails;
