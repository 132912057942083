import { getData } from '../common/storage';

const interceptor = (ax) => {
  ax.interceptors.request.use(
    (config) => {
      config.headers = {
        token: getData("userToken"),
        "Content-Type": "application/json",
      };
      return config;
    },

    (error) => {
      Promise.reject(error);
      console.log(error);
    }
  );

  ax.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      if (error?.response?.data?.message === "Please Provide Token") {
        window.location.href = "/user/login";
      }
      return Promise.reject(error);
    }
  );
};

export { interceptor };
