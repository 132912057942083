import React,{useState,useEffect} from 'react';
import { Link, useParams  } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { useNavigate } from "react-router-dom";
import Popover from '../admin/shared/Popover';
import SucessMessage from './shared/SucessMessage';
import { StoreModel } from '../common/constants';

function AddModel() {

 const {modelId} = useParams();
  const navigate = useNavigate();
  const [selectedPopoverId, setSelectedPopoverId] = useState(null);

  
  const [formData,setFormData] = useState({
    model:"",
  });
  const [errors,setErrors] = useState(
    {model: ''
   
    });
    useEffect(() => {
      if (modelId) {
        try {
           axios.post(StoreModel, formData, {
            headers: {
                'token': localStorage.getItem('token'), // Example authorization header
                'Content-Type': 'application/json', // Example content type header
                // Add more headers as needed
              }
          });
        //   Handle success
         
          
    
        } catch (error) {
          // Handle error
          console.error('Error edit file:', error);
        }
       
      }
    }, [modelId]);
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
 

  const handleClosePopover = () => {
  
    setSelectedPopoverId(false);

    navigate("/admin/models");
  };


  const handleSubmit = async (event) => {
   
    
    event.preventDefault();
  
  
    if (!formData.model) {
      setErrors({
        ...errors,
        model: 'Please enter a model name',
      });
      return;
    }
    
   

    try {
      await axios.post(StoreModel, formData, {
        headers: {
            'token': localStorage.getItem('token'), // Example authorization header
            'Content-Type': 'application/json', // Example content type header
            // Add more headers as needed
          }
      });
    //   Handle success
     
      setSelectedPopoverId(true);

    } catch (error) {
      // Handle error
      console.error('Error uploading file:', error);
    }
    console.log('Form Data:', formData);
    // Clear form fields and file input
    setFormData({
   
      model: '',
    });
    setErrors({
      model: ''
    });
  };




  return (
    <> 
    <div> 
      <h2>Models</h2>
      <div className="breadcrumbs">
        {" "}
        <Link to="/admin/dashboard">Home</Link>
        <span>{">"} </span>Add Model{" "}
      </div>
      </div>

      <div className='whitebox uploadproductpop' >
      <div className="container mx-auto p-4">
      <div className="flex flexwrapcol -mx-2 lg:gap-10">
       
        {/* Column 1 */}
       
        <div className="w-full md:w-1/2 px-2 mb-4"> 
        <h2>Add Model</h2>
        <p className='mb-3'>Add to Create Model</p>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mb-6">
          <label className="block text-sm   leading-5  w-1/2" htmlFor="modelName">
            Model Name
          </label>
         <div className="block w-full "> <input name="model"  value={formData.model}  onChange={handleInputChange} className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline" />
          {errors.model && <p style={{ color: 'red' }}>{errors.model}</p>}
          </div>

          </div>
        
          
        
       
          <div className="flex items-center mb-6">
            <label className="block text-sm   leading-5  w-1/2 "> 
            </label>
            <div  className="block w-full"> 
            <button className="redbtn" type="submit">
               Save
            </button>
          </div>
          </div>
          </form>
        </div>
     
     
      </div>
    </div>
      </div>
      {selectedPopoverId && (
          <Popover onClose={handleClosePopover}  isVisible={selectedPopoverId !== null}>
          
                    
       <SucessMessage>

          <h4>Model Added <br/> 
          Successfully</h4>
        </SucessMessage>

          </Popover>
        )}
    </ >
  )
}

export default AddModel