import React from 'react'

function UserHeader() {
  return (
    < >
        <div className='user-logo-header'>
   <img src='../images/user-logo.png'  alt='logo' /> 
   
      </div>

    </ >
  )
}

export default UserHeader