import React,{useState,useEffect} from 'react'
import Popover from  './Popover'   
import axios from 'axios';
import { GET_REDEEMED_POINTS_BY_TRANSACTION_ID,APPROVE_REDEEM_POINTS} from '../../common/constants';


const RedeemedItems = ({transactionId , redeemPoints,onClose}) => {

    const [products,setProducts] = useState([]);
    useEffect(() => {
        // Fetch data from the API
        axios.post(GET_REDEEMED_POINTS_BY_TRANSACTION_ID,{'transaction_id':transactionId}, {
          headers: {
            'token': localStorage.getItem('token'), // Example authorization header
            'Content-Type': 'application/json', // Example content type header
            // Add more headers as needed
          }
          })
      .then(response => {
        // Handle response
        setProducts(response.data.data);
       
     
       
       
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });
    
    
      }, []); // Empty dependency array means this effect runs only once on component mount   
    
  const [selectedPopoverId, setSelectedPopoverId] = useState(false);
  const [approveRewards, setApproveRewards] = useState(false);
  const handleOpenPopover = async () => {

    
    
    try {
        await axios.post(APPROVE_REDEEM_POINTS, {'transaction_id':transactionId}, {
          headers: {
            'token': localStorage.getItem('token'), // Example authorization header
            'Content-Type': 'application/json', // Example content type header
            // Add more headers as needed
          }
          });
        // Handle success
        setApproveRewards(true);
       
      } catch (error) {
        // Handle error
        console.error('Error uploading file:', error);
      }
  };

  const handleClosePopover = ( ) => {
    onClose();
    setApproveRewards(false);
  };
  return (
    <>
     <h2 style={{margin:"-20px 20px 10px 0px"}}>Redeemed Items</h2>
    <h4 className='mb-6'></h4>
    <ul className='rewardlist'>
    {
        products ? (products.map(product=>(
          
            <li><label>{product.product_name}</label> <label className='redfont'>{product.redeemed_points}</label></li>
        ))) : (<div>Loading Data</div>)
    }
       
     
       
    </ul>
    <div className='flex justify-center mt-6 mb-4'><button className='genreport-btn' style={{padding:"5px 10px",height:"auto",fontSize:"14px"}} onClick={() => handleOpenPopover()}>Approve Rewards</button></div>

    {approveRewards && (
          <Popover onClose={handleClosePopover} isVisible={approveRewards !== false}>
                    <h2 style={{margin:"-20px 20px 10px 0px"}}>Profile View</h2>
                        <h4 className='mb-6'>{products[0].user_name}</h4>
            <div className="flex  justify-center okicon"> 
                        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" stroke-width="1"/>
            <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" stroke-width="2"/>

                {/* <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/> */}
            
            </g>
            </svg>
            </div>  
            <div className='flex justify-center items-center text-center' style={{flexDirection:"column"}}> 
            <div  className='redfont text-2xl font-bold'> {redeemPoints}</div>    
            <h4>Rewards  Approved <br/> 
            Successfully</h4>
            </div> 
          </Popover>
        )}

    </>
  )
}

export default RedeemedItems