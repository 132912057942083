import React, { useEffect } from 'react';
import {  Outlet, useNavigate } from 'react-router-dom';
import '../user/User.css';
import { getData } from '../common/storage';

export const UserProtectedRoute = () => {
  const navigate = useNavigate();
  const isUserLoggedIn = getData('userToken');
  const isUserPage = ['/user/login/', '/user/login'].includes(window.location.pathname);

  useEffect(() => {
    if (!isUserLoggedIn) {
      window.location.href = "/user/login";
      navigate('/user/login', { replace: true });
    } else if (isUserLoggedIn && isUserPage) {
      navigate('/user/rewards', { replace: true });
    }
  }, [isUserLoggedIn, isUserPage, navigate]); 

  if (!isUserLoggedIn || isUserPage) {
    return null;
  }

  return (
    <div className='user-container'>
      <div className='container' style={{ margin: '0px auto' }}>
        <Outlet />
      </div>
    </div>
  );
};
