import React, { useCallback, useEffect, useState } from 'react'
import RewardsHeader from './shared/RewardsHeader'
import FormBoxsm from './shared/FormBoxsm'
import IconCircle from './shared/IconCircle'
import { SlTrophy } from "react-icons/sl";
import { MdArrowForwardIos } from "react-icons/md";
import CategoryBox from './shared/CategoryBox';
import { IoCameraOutline } from "react-icons/io5";
import { MdOutlineHistory } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import UserService from '../services/common/user.service';

function throttle(func, limit) {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  }
}

function Rewords() {
  const userServiceInstance = new UserService();
  const [myrewardspoints, setMyRewards] = useState(false);
  const [reedemPoints, setReedemPoints] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const reedem = location?.state?.reedem || false;

  useEffect(() => {
    if (reedem) {
      setMyRewards(true);
    }
  }, [reedem]);

  const showRewards = () => {
    setMyRewards(!myrewardspoints);
    setError("");
  }
  const scanQr = () => {
    navigate('/user/scanqr');
  }
  const gotoRewards = () => {
    navigate('/user/points');
  }
  const handleMenuClick = () => {
    setMyRewards(true);
  }

  const getRedeemPoints = (data) => {
    setReedemPoints(data);
  }

  // const notify = () => {
  //   if (reedemPoints !== 0) {
  //     setError(false);
  //     userServiceInstance.availablePoints().then(response => {
  //       if (response.data.statusCode === 201) {
  //         setIsSubmitting(true);
  //         toast.success('Claim request placed successfully');
  //         setTimeout(() => {
  //           setIsSubmitting(false);
  //         }, 5000);
  //       }
  //     }).catch((error) => {
  //       setError(error.response.data.message);
  //     });

  //   } else {
  //     setError("you don't have reward points");
  //   }
  // }

  const notify = useCallback(throttle(() => {
    if (reedemPoints !== 0) {
      setError(false);
      userServiceInstance.availablePoints().then(response => {
        if (response.data.statusCode === 201) {
          setIsSubmitting(true);
          toast.success('Claim request placed successfully');
          setTimeout(() => {
            setIsSubmitting(false);
          }, 5000);
        }
      }).catch((error) => {
        setError(error.response.data.message);
      });

    } else {
      setError("you don't have reward points");
    }
  }, 5000), [reedemPoints]);

  useEffect(() => {
    setError("");
  }, [])

  return (
    < >
      <RewardsHeader onPointsClick={handleMenuClick} getRedeemPoints={getRedeemPoints} />
      <div className='form-wrapper'>
        <FormBoxsm>
          <Toaster />
          <div className='flex  items-center gap-4'>
            <IconCircle> <SlTrophy /> </IconCircle>
            <div> <h4>Redeem your points</h4>
              {!myrewardspoints ? <p className='redfont cursor-pointer' onClick={showRewards}>Click to Redeem</p> : <></>}
            </div>
            <div className='redfont' style={{ fontSize: "18px", marginLeft: "auto" }}>
              {!myrewardspoints ? <MdArrowForwardIos className='cursor-pointer' onClick={showRewards} /> :
                <IoIosCloseCircleOutline className='cursor-pointer' onClick={showRewards} />
              }
            </div>
          </div>
          {
            myrewardspoints &&

            <div className={`showredempoints mt-2 pb-10 ${myrewardspoints ? 'show' : 'hide'}`}>
              <div className="input-group">
                {/* <label>  Enter reward points to claim  </label> */}
                <div className='inline-flex p-2'>
                  <h3 className=''>Available points:</h3>
                  <h3 className='ps-3'>{reedemPoints}</h3>
                </div>

                {/* <div>
                  <input
                    type='text' value={reedemPoints}
                    placeholder=''
                    onChange={(e) => setReedemPoints(e.target.value)}
                    disabled
                  />
                </div> */}
                {error && <p className='text-red-500 px-2'>{error}</p>}
              </div>
              <div className='text-center'>
                <button
                  onClick={notify}
                  className={`redbtn ${reedemPoints == "0" || isSubmitting ? 'disabled' : ''}`}
                  disabled={reedemPoints == "0" || isSubmitting} // Use the isSubmitting state to control the disabled attribute
                >Claim Rewards</button></div>
            </div>
          }
        </FormBoxsm>
        {!myrewardspoints &&
          <div className='grid twocol gap-5 mt-5'>
            <CategoryBox  ><div onClick={scanQr} className='flex justify-center items-center' style={{ flexDirection: "column" }}> <IconCircle> <IoCameraOutline /></IconCircle> <h4>Scan QR</h4>  </div></CategoryBox>
            <CategoryBox > <div onClick={gotoRewards} className='flex justify-center items-center' style={{ flexDirection: "column" }}>  <IconCircle> <MdOutlineHistory />  </IconCircle><h4>Reward History</h4> </div></CategoryBox>
            <CategoryBox> <div className='flex justify-center items-center' style={{ flexDirection: "column" }}>  <IconCircle> <MdOutlineLocationOn />  </IconCircle><h4>Near by Stores</h4> </div></CategoryBox>
          </div>
        }

      </div>


    </ >
  )
}

export default Rewords