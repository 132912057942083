 import React from 'react'
 
 function Footer() {
   return (
     < > 

<footer className='flex justify-center mt-3'> Dilipsindia Loyalty Programme</footer>
     </ >
   )
 }
 
 export default Footer