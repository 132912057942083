import React, { useState,useEffect } from "react";
import {useNavigate,  Link } from "react-router-dom";
import Popover from '../admin/shared/Popover';
import Preview from './shared/Preview';
import axios from "axios";
import config from "../config";
import { USER_REWARD_POINTS } from "../common/constants";
import Pagination  from "../common/Pagination";

function Plumber() {
 
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPopoverId, setSelectedPopoverId] = useState(null);
  const [plumbers, setPlumbersData] = useState([]);
  const [rewardPoints, setRewardPoints] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPlumber,setPlumberName] = useState({});

  const navigate = useNavigate();
  const limit = 10; // Number of items per page

  useEffect(() => {
    // Fetch data from the API
  
    axios.post(`${config.backendUrl}/admin/plumbers`,{    params: {
      page: currentPage,
    
    },}, {
      headers: {
        'token': localStorage.getItem('token'), // Example authorization header
        'Content-Type': 'application/json', // Example content type header
        // Add more headers as needed
      }
      })
    .then(response => {
      // Handle response
      console.log(response);
      setPlumbersData(response.data.data);
      setTotalPages(Math.ceil(response.data.total_count / limit));
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
  
  
  }, [currentPage, limit]); // Empty dependency array means this effect runs only once on component mount
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleOpenPopover = (userId,name) => {
    setPlumberName({user_name:name,user_id:userId});
    const data = {
      user_id: userId,
   
    };
  
    axios.post(USER_REWARD_POINTS,data, {
      headers: {
        'token': localStorage.getItem('token'), // Example authorization header
        'Content-Type': 'application/json', // Example content type header
        // Add more headers as needed
      }
      })
    .then(response => {
      // Handle response
      setRewardPoints(response.data.data);
   
     setSelectedPopoverId(userId);
     
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
  
  
  };

  const handleClosePopover = () => {
    setSelectedPopoverId(null);
  };
  const filteredItems = searchTerm
    ? plumbers.filter(
        (item) =>
          item.items.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.scannedDate.includes(searchTerm) ||
          item.redeemedByUserType
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.redeemedDate.includes(searchTerm)
      )
    : plumbers;



  
  const gotoReports = () => {
    navigate('/admin/reports');
};



  return (
    <div>
      <div className="flex justify-between items-center"> 
      <div> 
      <h2>Plumbers</h2>
      <div className="breadcrumbs">
        {" "}
        <Link to="/admin/dashboard">Home</Link>
        <span>{">"} </span>Plumbers{" "}
      </div>
      </div>
      
      <div>
        <button  onClick={gotoReports}  className='genreport-btn'>Generate Report</button>
      </div>
      </div>
      <div className="whitebox">
        <div>
          <table className="tablegrid" width={"100%"}>
            <thead>
              <tr>
                <th>SNo</th>
                <th>Name</th>
                <th>Location</th>
                <th>Joined Date</th>
                <th>Accumulated Points </th>
                <th> Points Redeemed</th>

                <th> Mobile Number</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {plumbers.map((item, index) => (
                <tr key={index}>
                  <td>  {index + 1 + (currentPage - 1) * limit}</td>
                  <td onClick={() => handleOpenPopover(item.id,item.name)} className="redfont">{item.name}</td>
                  <td>{item.location}</td>
                  <td>{item.joined_dt}</td>
                  <td>{item.Accumulated_points}</td>
                  <td>{item.points_redeemed}</td>
                  <td>{item.mobile}</td>
                  <td>
                    <a href={`mailto:NA`}>{item.email_id}</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-between">
            <div> </div>
            <div className='pagenation'> 
             
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
             />

           
          </div>
        </div>
      </div>


      {selectedPopoverId && (
          <Popover onClose={handleClosePopover} isVisible={selectedPopoverId !== null}>
            <Preview reward_points={rewardPoints} popOverClose={handleClosePopover} userData={selectedPlumber}></Preview>
          </Popover>
        )}
    </div>
  );
}

export default Plumber;
