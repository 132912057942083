import React, { useEffect, useRef, useState } from 'react';
import RewardsHeader from './shared/RewardsHeader';
import FormBox from './shared/FormBox';
import { useLocation } from 'react-router-dom';
import Confetti from 'react-confetti';
import toast, { Toaster } from 'react-hot-toast';
import UserService from '../services/common/user.service';

function ClaimRewards() {
  const userServiceInstance = new UserService();
  const [confetti, setConfetti] = useState(false);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setloading] = useState(true);
  const location = useLocation();
  const scannedData = location.state?.scannedData || "";
  const { neighbourhood, county, city, state, postcode } = location?.state?.locationData || {};
  const hasGrantedRewardsRef = useRef(false);

  const grantRewards = () => {
    setloading(true);
    const userAddress = neighbourhood + " " + county + " " + city + " " + state + " " + postcode;
    const payload = { location: userAddress, "encrypyData": scannedData };
    userServiceInstance.productScan(payload).then(response => {
      if (response.data.statusCode === 201) {
        triggerConfetti();
        toast.success('Rewards Granted Successfully');
      }
    }).catch((error) => {
      setError(true);
      setErrorMessage(error.response.data.message);
    }).finally(() => setloading(false))

  };

  const fetchProductDetails = async () => {
    const payload = { encrypyData: scannedData };
    userServiceInstance.productDetails(payload).then(response => {
      if (response?.data?.statusCode === 200) {
        setProduct(response?.data?.data);
      }
    }).catch((error) => console.log(error?.response?.data?.message))
  };


  const triggerConfetti = () => {
    setConfetti(true);
    setTimeout(() => {
      setConfetti(false);
    }, 5000);
  };


  useEffect(() => {
    if (!hasGrantedRewardsRef.current) {
      grantRewards();
      fetchProductDetails();
      hasGrantedRewardsRef.current = true;
    }
  }, []);



  return (
    <>
      <RewardsHeader render={confetti} />
      <div style={{ marginTop: "50px" }}>
        <FormBox>
          <div>
            <Toaster />
            {confetti && <Confetti numberOfPieces={2000} />}
            <label>Item Name</label>
            <h4>{product?.model_name} - {product?.name}</h4>
            <div> <img src={product?.imageUrl} alt={product?.name} />  </div>
            <div className='text-center'>

              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <h1 className='redfont font-bold'>{errorMessage}</h1>
              ) : (
                <>
                  <h1>Congratulations</h1>
                  <h5>You have received</h5>
                  <h1 className="redfont font-bold reward-font-size">{product?.reward_points}</h1>
                  <div>Reward points</div>
                </>
              )}
            </div>
          </div>
        </FormBox>
      </div>
    </>
  );
}

export default ClaimRewards;
