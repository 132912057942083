import React,{useState,useEffect} from 'react';
import { Link  } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { useNavigate } from "react-router-dom";
import Popover from '../admin/shared/Popover';
import SucessMessage from './shared/SucessMessage';
import { BulkUpload } from '../common/constants';

function AddProducts() {

  const [models, setModelData] = useState([]);
  const navigate = useNavigate();
  const [selectedPopoverId, setSelectedPopoverId] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [zipFile, setZipFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  
  useEffect(() => {
    // Fetch data from the API
  
    axios.post(`${config.backendUrl}/admin/models`,{}, {
      headers: {
        'token': localStorage.getItem('token'), // Example authorization header
        'Content-Type': 'application/json', // Example content type header
        
        // Add more headers as needed
      }
      })
    .then(response => {
      // Handle response
      setModelData(response.data.data);

      console.log(models);
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
  
  
  }, []); // Empty dependency array means this effect runs only once on component mount

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileNameParts = selectedFile.name.split('.');
    const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    
    if (extension === 'xls' || extension === 'xlsx') {
      setExcelFile(selectedFile);
    } else if (extension === 'zip') {
      setZipFile(selectedFile);
    } else {
      setErrorMessage('Only Excel (xls, xlsx) and ZIP files are allowed.');
    }
  };



  const [formData,setFormData] = useState({
    name:"",
    modal:"",
    image:null
  });
  const [errors,setErrors] = useState(
    {productName: '',
    modelName: '',
    image: '',});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleOpenPopover = () => {
    setSelectedPopoverId(true);
  
  };



  const handleClosePopover = () => {
  
    setSelectedPopoverId(false);

    navigate("/admin/products");
  };

  const handleBulkSubmit = async (event) => {
    event.preventDefault();

    if (!excelFile || !zipFile) {
      setErrorMessage('Please upload one Excel file and one ZIP file.');
      return;
    }

    // Handle form submission or further processing here
    console.log('Excel file:', excelFile.name);
    console.log('ZIP file:', zipFile.name);
    const formData = new FormData();
    formData.append('excel', excelFile);
    formData.append('image', zipFile);

    try {
      const response = await axios.post(BulkUpload, formData, {
        headers: {
          'token': localStorage.getItem('token'), // Example authorization header
          // 'Content-Type': 'application/json', // Example content type header
          'Content-Type': 'multipart/form-data'
          // Add more headers as needed
        }
      });
      console.log('Files uploaded successfully:', response.data);

      setSelectedPopoverId(true);
      // Handle further actions after successful upload
    } catch (error) {
      console.error('Error uploading files:', error);
      // Handle error scenario
    }

  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
   
    // Check if a file is selected
    if (!selectedFile) {
   
      return;
    }

    // Check if the selected file is an image
    if (!selectedFile.type.startsWith('image/')) {
      setErrors({ ...errors, image: 'Please select an image file.' });
      return;
    }

    // Read the selected image file and set preview image
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedFile);
    setFormData({ ...formData, image: selectedFile });
    // Reset error message and set image file
    setErrorMessage('');
    setImageFile(selectedFile);
  };

  const handleSubmit = async (event) => {
   
    
    event.preventDefault();
    if (!formData.image) {
      setErrors({
        ...errors,
        image: 'Please select a file',
      });
      return;
    }
    if (!formData.name) {
      setErrors({
        ...errors,
        name: 'Please enter a product name',
      });
      return;
    }
    if (!formData.modal) {
      setErrors({
        ...errors,
        modal: 'Please enter a model name',
      });
      return;
    }
    
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('modal', formData.modal);
    formDataToSend.append('image', formData.image);

    try {
      await axios.post(`${config.backendUrl}/admin/products/store`, formDataToSend, {
    
        headers: {
          'token': localStorage.getItem('token'), // Example authorization header
          'Content-Type': 'multipart/form-data', // Example content type header
          // Add more headers as needed
        }
      });
      // Handle success
     
      setSelectedPopoverId(true);

    } catch (error) {
      // Handle error
      console.error('Error uploading file:', error);
    }
    console.log('Form Data:', formData);
    // Clear form fields and file input
    setFormData({
      name: '',
      modal: '',
      image: null,
    });
    setErrors({
      name: '',
      modal: '',
      image: '',
    });
  };




  return (
    <> 
    <div> 
      <h2>Products</h2>
      <div className="breadcrumbs">
        {" "}
        <Link to="/admin/dashboard">Home</Link>
        <span>{">"} </span>Add Products{" "}
      </div>
      </div>

      <div className='whitebox uploadproductpop' >
      <div className="container mx-auto p-4">
      <div className="flex flexwrapcol -mx-2 lg:gap-10">
       
        {/* Column 1 */}
       
        <div className="w-full md:w-1/2 px-2 mb-4"> 
        <h2>Add Product</h2>
        <p className='mb-3'>Add to Create Product</p>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mb-6">
          <label className="block text-sm   leading-5  w-1/2" htmlFor="productName">
            Product Name
          </label>
         <div className="block w-full "> <input name="name"  value={formData.name}  onChange={handleInputChange} className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline" />
          {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
          </div>

          </div>
        
          <div className="flex items-center mb-6">
          <label className="block text-sm   leading-5  w-1/2"  htmlFor="modalName">
            Model
          </label>
         <div className="block w-full">
          <select name="modal"  value={formData.modal}   onChange={handleInputChange} className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline" >
          <option value="">Select a model</option>
          {models.map((model, index) => (
            <option key={index} value={model.id}>{model.model}</option>
          ))}
          </select>
             
          {errors.modal && <p style={{ color: 'red' }}>{errors.modal}</p>}</div> 

          </div>
          <div className="flex items-center mb-6">
        <label className="block text-sm   leading-5 w-1/2">Add Image</label>
        <div className="block relative w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
          {/* Your input to accept files */}
          <input
            type="file" name="image"
            onChange={handleImageChange } 
            multiple
            accept=".jpg,.jpeg,.png" // Adjust accordingly for image files
            style={{ opacity: "0", position:"absolute",width:"100%" }} // Hide the default file input
            id="file-upload" // Id for the label
          />
         
          {/* Label to trigger the hidden file input */}
          <label htmlFor="file-upload" className="flex justify-between items-center">
         <div> Upload Image(s) </div>  <button style={{height:"20px", width:"100px"}} className='redbtn'> Browse</button>
          </label>
          {previewImage && <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", maxHeight: "200px" }} />}
          {/* List of uploaded images */}
        
        </div>
        
          </div>
         <div className="flex">{errors.image && <p style={{ color: 'red' }}>{errors.image}</p>}  </div> 
          <div className="flex items-center mb-6">
            <label className="block text-sm   leading-5  w-1/2 "> 
            </label>
            <div  className="block w-full"> 
            <button className="redbtn" type="submit">
              Upload Product
            </button>
          </div>
          </div>
          </form>
        </div>
     
        {/* Column 2 */}
        
        <div className="w-full md:w-1/2 px-2 mb-4">
     
        <p className='mb-3'>Batch Creation</p>
       
        
        <div>
        <form onSubmit={handleBulkSubmit}>  
          <div className='mb-3' style={{border:"1px dotted #000",borderRadius:"5px" ,padding:"20px"}}> 
          <div className='filedragdrop'> 
          <input
            type="file"
            onChange={handleFileChange}
            multiple // Remove this attribute if you want to allow single file uploads only
            accept=".xls,.xlsx,.zip" // Specify allowed file formats here
          />
          
     <div className='dragtext'> Upload files</div>
      </div>
      <div>
      
      </div>
      </div>
      {excelFile && <p>Excel file: {excelFile.name}</p>}
        {zipFile && <p>ZIP file: {zipFile.name}</p>}

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
     
      <div className='mt-3'> 
          <button className="redbtn  " type="submit">
            Upload Products
          </button>
          </div>
      </form> 
      <div>
      
      
      </div>
     
    </div>

          
          {/* Upload Product Button */}
      
        </div>
      </div>
    </div>
      </div>
     
      {selectedPopoverId && (
          <Popover onClose={handleClosePopover}  isVisible={selectedPopoverId !== null}>
          
                    
       <SucessMessage>

          <h4>Product Added <br/> 
          Successfully</h4>
        </SucessMessage>

          </Popover>
        )}
    </ >
  )
}

export default AddProducts