import React, { useRef, useState } from 'react';
import UserHeader from './shared/UserHeader';
import FormBox from './shared/FormBox';
import { CiMobile4 } from "react-icons/ci";
import CusPopOver from './shared/CusPopOver';
import LocationComponent from './shared/LocationComponent';
import { encryptData } from '../common/storage';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/common/user.service';

function UserLogin() {
  const userServiceInstance = new UserService();
  const [mobileNumber, setMobileNumber] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(null);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [selectedPopoverId, setSelectedPopoverId] = useState(false);
  const [role, setRole] = useState(2);
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [responseError, setResponseError] = useState('');
  const navigate = useNavigate();
  const intervalRef = useRef(null);

  const validateMobileNumber = (number) => {
    return /^\d{10}$/.test(number);
  };

  const sendOtp = async () => {
    if (validateMobileNumber(mobileNumber)) {
      const payload = { "mobile": mobileNumber }
      userServiceInstance.userRegister(payload).then(response => {
        if (response?.data?.statusCode === 201) {
          setOtpSent(true);
          startTimer(55);
          setIsNumberValid(true);
          // setOtp(response?.data?.data?.otp);
        }
      }).catch(error => {
        setMobileNumberError(error?.response?.data?.message);
        setIsNumberValid(false);
      })
    } else {
      setMobileNumberError('Please enter a valid 10-digit mobile number.');
      setIsNumberValid(false)
    }
  };

  const startTimer = (duration) => {
    if (intervalRef.current) clearInterval(intervalRef.current); 
    setTimer(duration);
    const interval = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime === 0) {
          clearInterval(interval);
          return null;
        }
        return prevTime - 1;
      });
    }, 1000);
    intervalRef.current = interval; 
  };


  const validateOTP = () => {
    otp === "" ? setOtpError("Please enter otp") : setOtpError("");
    role === "" ? setRoleError("Please select role") : setRoleError("");
    return (otpError === "" && roleError === "") ? true : false;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = validateOTP();
    if (result) {
      const payload = { "mobile": mobileNumber, "otp": otp, "role_id": role }
      userServiceInstance.userRegister(payload).then(response => {
        if (response.data.statusCode === 200) {
          setSelectedPopoverId(true);
          encryptData("userToken", response?.data?.Token);
        }
      }).catch(error => { setResponseError(error.response?.data?.message); })
    }
  };

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
    setOtp("");
    if (/^\d{10}$/.test(event.target.value)) {
      setMobileNumberError("");
    }
    if (otpSent) {
      setOtpSent(false);
    }
  };

  const handleClosePopover = () => {
    navigate('/user/personaldetails', { replace: true });
    setSelectedPopoverId(null);
  };


  return (
    <div className='user-container'>
      <UserHeader />

      <FormBox>
        <form onSubmit={handleSubmit}>
          <h3>Login / Signup</h3>
          <p className='mb-3'>Please Login / Signup to loyalty program by DiiPSindia</p>
          <div className="input-group">
            <label htmlFor="mobile-number">Enter Mobile Number</label>
            <div className='custominput'>
              <input
                type='text'
                value={mobileNumber}
                placeholder='Enter Mobile number'
                onChange={handleMobileNumberChange}
                className={!isNumberValid ? 'invalid-input' : ''}
              />
              <div className='inputicon'><CiMobile4 /></div>
            </div>
            {mobileNumberError && <div className="error-message text-red-500">{mobileNumberError}</div>}

            {otpSent && (
              <div className='text-right redfont '>
                <button type="button" onClick={() => { setOtpSent(false); setOtp(""); }} >Change Number</button>
              </div>
            )}
            {!otpSent && (
              <div className='text-center  mt-4'>
                <button type="button" className='redbtn' onClick={sendOtp}>Send OTP</button>
              </div>
            )}
          </div>

          {/* OTP input field and resend option */}
          {otpSent && (
            < >
              <div className="input-group">
                <label htmlFor="otp">Enter OTP</label>
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => { setOtp(e.target.value); setOtpError(e.target.value === "" ? "please enter otp" : "") }}
                />
                {otpError && <div className="error-message text-red-500">{otpError}</div>}
              </div>


              <div className="input-group flex gap-4">
                <div className='flex items-center gap-1'>
                  <label className="custom-radio-button">
                    <input type="radio" id="plumber" name="user-type" value="plumber" onClick={() => setRole(2)} checked={role === 2} />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="plumber">Plumber</label></div>
                <div className='flex items-center  gap-1'>
                  <label className="custom-radio-button">
                    <input type="radio" id="customer" name="user-type" value="customer" onClick={() => setRole(3)} checked={role === 3} />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="customer">Customer</label></div>
              </div>
              {roleError && <div className="error-message text-red-500">{roleError}</div>}
              {responseError && <div className="error-message text-red-500">{responseError}</div>}

              <div className='text-center redfont mb-2'>
                {timer !== null ? `Resend OTP ${timer}` : (
                  <button onClick={() => sendOtp()}>Resend OTP</button>
                )}
              </div>

              <div className='text-center'>
                <button type="submit" className='redbtn'>Submit</button>
              </div>
            </ >
          )}
        </form>
      </FormBox>


      {selectedPopoverId && (
        <CusPopOver onClose={handleClosePopover} isVisible={selectedPopoverId !== null}>
          <LocationComponent onClose={handleClosePopover} ></LocationComponent>
        </CusPopOver>
      )}
    </div>

  );
}

export default UserLogin;
