import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import Header from "../admin/shared/Header";
import Footer from "../admin/shared/Footer";

export const ProtectedRoute= ({ children }) => {
  const { token } = useAuth();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/admin/login" />;
  }


  // If authenticated, render the child routes
 
  
  return (children ? children :<><Header/>  <div className='container ' style={{margin: "0px auto" }}> 
 <div className="bodyheight">  
  <Outlet /> 
  </div>
 <Footer></Footer>
  
  </div> </>);
};
