import React,{useState,useEffect} from 'react' 
import { IoIosSearch } from "react-icons/io";
 import Popover from '../admin/shared/Popover';
import { useNavigate,Link  } from 'react-router-dom';
import RedeemedItems from './shared/RedeemedItems';
import DatePicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import config from '../config';
import axios from 'axios';
import { BsExclamationCircle } from "react-icons/bs";
import { APPROVE_REDEEM_POINTS, DASHBOARD } from '../common/constants';
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
import { RiShoppingBagLine } from "react-icons/ri";
import { MdPlumbing } from "react-icons/md";
import { HiOutlineUser } from "react-icons/hi";
import { BsQrCodeScan } from "react-icons/bs";
import { TbReportSearch } from "react-icons/tb";
import { MdOutlineFormatListBulleted } from "react-icons/md"; 



function Dashboard() {
 

  const [statistics, setStatictics] = useState([]);
  const [selectedPopoverId, setSelectedPopoverId] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [approveAll, setApproveAll] = useState(false);
  const [custmerCount,setCustomersCount] = useState(0);
  const [qrCount,setqrCount] = useState(0);


  const [selected, setSelected] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [redeemPoints, setSelectedRedeemPoints] = useState(0);

    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [formData, setFormData] = useState({
      startDate: null,
      endDate: null,
      search: '',
    });
  

      useEffect(() => {
        // Fetch data from the API
       
        // getRedeemRecords();
        getDashBoardData();
    
    
      }, []); // Empty dependency array means this effect runs only once on component mount
      useEffect(() => {
      
          getRedeemRecords();
      
      }, [formData.startDate, formData.endDate,formData.search]); // Depend on startDate and endDate
      const getRedeemRecords =()=>{
        axios.post(`${config.backendUrl}/admin/redeem`,{formData}, {
          headers: {
            'token': localStorage.getItem('token'), // Example authorization header
            'Content-Type': 'application/json', // Example content type header
            // Add more headers as needed
          }
          })
        .then(response => {
          // Handle response
          console.log(response);
          setStatictics(response.data.data);
  
          console.log(statistics);
         
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
        });
      }

      const getDashBoardData = ()=>{
        axios.post(DASHBOARD,{}, {
          headers: {
            'token': localStorage.getItem('token'), // Example authorization header
            'Content-Type': 'application/json', // Example content type header
            // Add more headers as needed
          }
          })
        .then(response => {
          // Handle response
       
           setCustomersCount(response.data.data.customers);
           setqrCount(response.data.data.qrCount);
         
         
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
        });
      }
     

      const handleOpenPopover = (id,redeem_points) => {
        setSelectedPopoverId(id);
        setSelectedRedeemPoints(redeem_points);
      };

      const OpenApproveSelectedPopUp=()=>{

       
        setApproveAll(true);
        

      }
    
      const handleClosePopover = () => {
        getRedeemRecords();
        setSelectedPopoverId(null);
      };

 
  
    
     
      const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };
  

      function handleChange(date, fieldName) {
        setFormData(prevState => ({
          ...prevState,
          [fieldName]: date
        }));
      }
      
      function handleSearchChange(event) {
       
        const { name, value } = event.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      }
      
  
      
      // Use filteredItems instead of data for pagination
      const currentItems = statistics.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
      );

      const totalPages = Math.ceil(statistics.length / itemsPerPage);
      const renderPageNumbers = () => {
        let pages = [];
        for (let i = 0; i < totalPages; i++) {
            let isActive = currentPage === i;
          pages.push(
            <button
              key={i}
              style={{ margin: 5 }}
              onClick={() => handlePageChange(i)}
              className={isActive ? 'active' : ''}
              disabled={currentPage === i}
            >
              {i + 1}
            </button>
          );
        }
        return pages;
      };
      const navigate = useNavigate();
      const goToProductList = () => {
        navigate('/admin/products');
    };
    const gotoPlumber = () => {
        navigate('/admin/plumbers');
    };
    const gotoCustomers = () => {
        navigate('/admin/customers');
    };
    const gotoQrList = () => {
        navigate('/admin/qrlist');
    };
    const gotoGeneratedQrList = () => {
      navigate('/admin/generated-qrs');
  };
    const gotoQrGenerate = () => {
        navigate('/admin/qrgenerate');
    };
    const gotoReports = () => {
        navigate('/admin/reports');
    };
   
    // Function to handle individual checkbox selection
  const handleCheckboxChange = (id) => {
    const newSelectedIds = selectedIds.includes(id)
      ? selectedIds.filter((selectedId) => selectedId !== id)
      : [...selectedIds, id];
    setSelectedIds(newSelectedIds);
  };
     // Function to handle "Check All" checkbox selection
  const handleCheckAllChange = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      const allIds = currentItems.map((item) => item.transaction_id);
      setSelectedIds(allIds);
    }
    setSelectAll(!selectAll);
  };
  
  const handleCloseApproveAllPopover=()=>{

    getRedeemRecords();
    setApproveAll(false);

  }
  const approveSelectedRecords =()=>{
    const transactionIdsString = selectedIds.join(',');
      // Fetch data from the API
      axios.post(APPROVE_REDEEM_POINTS,{'transaction_id':transactionIdsString}, {
        headers: {
          'token': localStorage.getItem('token'), // Example authorization header
          'Content-Type': 'application/json', // Example content type header
          // Add more headers as needed
        }
        })
    .then(response => {
      // Handle response
      getRedeemRecords();
      handleCloseApproveAllPopover();
      setSelectAll(false);
     
     
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
  }
  return (
    <> 
        <h2>Dashboard</h2>        
         <div className='breadcrumbs'> <Link to="/admin/dashboard">Home</Link> </div>
    <div className="categories-list">
        <div  onClick={goToProductList} className='categories-box'>       
            <div className='flex justify-end'> 
            <RiShoppingBagLine /> 
         </div>
            <h4>Our Products</h4>
        </div>

        <div onClick={gotoPlumber} className='categories-box'>
            <div className='flex justify-end'> 
            <MdPlumbing />
       </div>
            <h4>Plumbers</h4>
        </div>

        <div onClick={gotoCustomers} className='categories-box'>
            <div className='flex justify-end'>
              <HiOutlineUser /> 
       </div>
            <h4>Customers</h4>
        </div>

        <div  onClick={gotoGeneratedQrList} className='categories-box'>
            <div className='flex gap-4 items-center'> 
        <div className='redcircle'>{qrCount} </div>
            <div style={{fontSize:"16px"}}>QR’s  <br/>  Generated</div>
            </div>
        </div>


        <div  onClick={gotoQrGenerate} className='categories-box'>
            <div className='flex justify-end'> 
              <BsQrCodeScan /> 
            </div>
            <h4>Generate QR</h4>
        </div>

        <div  onClick={gotoReports} className='categories-box'>
            <div className='flex justify-end'> 
             <TbReportSearch />
            </div>
            <h4>Reports</h4>
        </div>

        <div   onClick={gotoQrList}  className='categories-box'>
            <div className='flex justify-end'>
               <MdOutlineFormatListBulleted  /> 
            </div>
            <h4>QR List</h4>
        </div>

        <div onClick={gotoCustomers}  className='categories-box'>
        <div className='flex gap-4 items-center'> 
            <div className='redcircle'>{custmerCount}</div>
            <div style={{fontSize:"16px"}}>Active
            <br/>  Customers</div>
            </div>
        </div>


    </div>
    
    <div  className='whitebox'>
    <form>
        <div className='flex justify-between mb-4'> 
        <h4> Statistics</h4>
        <div className='flex gap-4'>  
            
            <div className="date-filters flex">
            
          
          <div className='flex items-center'><DatePicker 
              selected={formData.startDate} 
              onChange={(date) => handleChange(date, 'startDate')}
              name="startDate" 
             
              placeholderText="From date"
            /> <FaRegCalendarAlt /></div>

         
           <div className='flex items-center'><DatePicker 
              selected={formData.endDate} 
              onChange={(date) => handleChange(date, 'endDate')}
              name="endDate" 
              placeholderText="To date" 
            /><FaRegCalendarAlt />
          </div>

         
        </div>
     
  
      <div className='flex table-search'>   
      <input
          type="text"
          placeholder="Search"
          value={formData.search}
          name="search"
          onChange={handleSearchChange}
        />
        <IoIosSearch />

      </div>
    </div>
    
</div>
</form>

    <div>
      <table className='tablegrid' width={"100%"}>
        <thead>
          <tr>
            <th><input type="checkbox"      checked={selectAll}
                onChange={handleCheckAllChange} /></th>
            <th>SNo</th>
            {/* <th>Items</th> */}
            <th>Scanned Date</th>
            <th>Cash Reward</th>
            <th>Redeemed By </th>
            <th>User Type</th>
            <th>Redeemed Date</th>
            <th>Approve Rewards</th>
          </tr>
        </thead>
        <tbody>
        {currentItems.length === 0 ? (
          <tr>
            <td colSpan="8" align="center">No records available</td>
          </tr>
        ) : (
          currentItems.map((item, index) => (
            <tr key={index}>
              <td><input type="checkbox"    checked={selectedIds.includes(item.transaction_id)}  onChange={() => handleCheckboxChange(item.transaction_id)} /></td>
              <td>{index + 1}</td>
              {/* <td>{item.product_name}</td> */}
              <td>{item.scanned_date}</td>
              <td>{item.total_redeem_points}</td>
              <td className='redfont'>{item.user_name}</td>
              <td>{item.role_name}</td>  
              <td>{item.redeemed_date}</td>              
              <td><button className='aprove-btn' onClick={() => handleOpenPopover(item.transaction_id,item.total_redeem_points)}>Approve</button></td>
            </tr>
          ))
        )}
      </tbody>
      </table>

      <div className='flex justify-between mt-4'>
      {currentItems.length !== 0 && (
      <button type="button" className='redbtn' onClick={OpenApproveSelectedPopUp}>Approve All</button>
    )}
        <div className='pagenation'> 
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 0} >
        <IoIosArrowBack />

        </button>
        
        {/* Page Numbers */}
        {renderPageNumbers()}
        
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages - 1}
        >
         <IoIosArrowForward />

        </button>
        </div>
      </div>
    </div>

    </div>

    {selectedPopoverId && (
          <Popover onClose={handleClosePopover}  isVisible={selectedPopoverId !== null}>
            <RedeemedItems  onClose={handleClosePopover}  transactionId={selectedPopoverId} redeemPoints={redeemPoints} ></RedeemedItems>
          

          </Popover>
        )}

    {approveAll && (
    <Popover onClose={handleCloseApproveAllPopover}  isVisible={approveAll !== null}>
           
                     
    <div className="flex  justify-center okicon"> 
    <BsExclamationCircle />

    </div>  
    <div className='flex justify-center items-center text-center' style={{flexDirection:"column"}}> 
    
    <h4>Are you sure want to approve  ? <br/> 
    </h4>
<div className='flex items-center justify-center gap-4 mt-5' >
<button  onClick={handleCloseApproveAllPopover} className='greybtn'>NO</button>
    <button onClick={approveSelectedRecords}  className='redbtn'>YES</button>

</div>
  

    </div> 

          </Popover>
        )} 
    </>
  )
}

export default Dashboard