import React, { useState, useEffect } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import SucessMessage from './shared/SucessMessage';
function ProductsList() {
  const [products, setProductsData] = useState([]);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState([]);
  const gotoAddProducts = () => {
    navigate('/admin/addproducts');
  };
  const onClaim = () => {
    setShowSuccessMessage(true); 
setTimeout(() => {
  setShowSuccessMessage(false); 
},3000)
    
  };
  useEffect(() => {
    // Fetch data from the API

  
    axios.post(`${config.backendUrl}/admin/products`,{}, {
      headers: {
        'token': localStorage.getItem('token'), // Example authorization header
        'Content-Type': 'application/json', // Example content type header
        // Add more headers as needed
      }
      })
  .then(response => {
    // Handle response
    console.log(response);
    setProductsData(response.data.data)
  })
  .catch(error => {
    // Handle error
    console.error('Error:', error);
  });


  }, []); // Empty dependency array means this effect runs only once on component mount

  return (
    <div>
    {/* {showSuccessMessage &&  <SucessMessage value={"Rewards Granted Succesfully"} />} */}

        <div className="flex justify-between items-center"> 
      <div> 
      <h2>Products</h2>
      <div className="breadcrumbs">
        {" "}
        <Link to="/admin/dashboard">Home</Link>
        <span>{">"} </span>Add Products{" "}
      </div>
      </div>
      
      <div>
        <button  onClick={gotoAddProducts}  className='genreport-btn'>Add Products</button>
      </div>
      </div>

        <div className="product-list">
            { products ?  (
              products.map(product => (
                <div className='product-listitem' key={product.id}>
                    <figure className="productimage">
                        <img src={product.imageUrl} alt="description" />
                        {/* <figcaption>{product.name}</figcaption> */}
                        <div className="prodcuttitle">
                        <div className="prodcutccode"><span>{product.model_name}</span></div>
                        <h4><a>{product.name}</a></h4>
                        </div>  
                    </figure>
                </div>
            ))) : (<div>Loading Data</div>)}
        </div>
    </div>
  );
}
export default ProductsList;

