
import AuthProvider from "./provider/authProvider";
import Routes from "./routes";
import './App.css';

function App() {
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}

export default App;



// import React, { useState } from 'react';
// import Login from './admin/Login';
// import Dashboard from './admin/Dashboard';
// import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// import Productslist from './admin/Productslist';
// import Plumber from './admin/Plumber';
// import Customers from './admin/Customers';
// import QrList from './admin/QrList';
// import QrGenerate from './admin/QrGenerate';  
// import Reports from './admin/Reports';
// import AddProducts from './admin/AddProducts'; 
// // import { AuthProvider } from './admin/actions/AuthContext';
// import {AuthProvider} from './admin/actions/AuthContext'
// import AdminLayout from './admin/AdminLayout';
// import UserLayout from './user/UserLayout';
// import UserLogin from './user/UserLogin';
// import PersonalDetails from './user/PersonalDetails';
// import Rewords from './user/Rewords';
// import RewardPoints from './user/RewardPoints';
// import RewardsHistory from './user/RewardsHistory';
// import ClaimRewards from './user/ClaimRewards';

// function App() { 
//   const [isLoggedIn, setIsLoggedIn] = useState(false); 
  
//   const ProtectedRoute = ({ children }) => {
//     if (!isLoggedIn) {      
//       return <Navigate to="/admin/login" />;
//     }
    
//     return children;
//   };

//   return (
//     <> 
      
     
//         <BrowserRouter>
//         <AuthProvider> 
       
        
//              <Routes>
          
//             <Route  isLoggedIn={isLoggedIn} path="/admin" element={<AdminLayout />} >
//             <Route index  element={ <Login setIsLoggedIn={setIsLoggedIn}  /> } />
//             <Route path="/admin/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
//             <Route path="/admin/products" element={<ProtectedRoute><Productslist /></ProtectedRoute>} />
//             <Route path="/admin/addproducts" element={<ProtectedRoute><AddProducts /></ProtectedRoute>} />
//             <Route path="/admin/plumbers" element={<ProtectedRoute><Plumber /></ProtectedRoute>} />
//             <Route path="/admin/customers" element={<ProtectedRoute><Customers /></ProtectedRoute>} />
//             <Route path="/admin/qrlist" element={<ProtectedRoute><QrList /></ProtectedRoute>} />
//             <Route path="/admin/qrgenerate" element={<ProtectedRoute><QrGenerate /></ProtectedRoute>} />
//             <Route path="/admin/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
//             </Route>


//             <Route path="/user" element={<UserLayout />} >
//                  <Route index  element={ <UserLogin  /> } />
//                  <Route path="/user/personaldetails"  element={ <PersonalDetails  /> } />
//                  <Route path="/user/rewards"  element={ <Rewords  /> } />
//                  <Route path="/user/points"  element={ <RewardPoints  /> } />
//                  <Route path="/user/rewardshistory"  element={ <RewardsHistory  /> } />
//                  <Route path="/user/claimrewards"  element={ <ClaimRewards  /> } />

//           </Route>
            
//           </Routes>
      
//           </AuthProvider>
//         </BrowserRouter>  

        
      
     
//     </>
    
//   );
// }

// export default App;
