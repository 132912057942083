import React from 'react'

function FormBoxsm({children}) {
  return (
 
    <div className='formboxsm'>  {children}
   </div>
         
  )
}

export default FormBoxsm