import React,{useState} from 'react'
import { Link  } from 'react-router-dom';
import { IoIosSearch } from "react-icons/io";
import config from "../config";

const data = [
  
  {
    id: 2,
    fileName: "PlumbersList.xlsx",
    rewardPoints: 1200,
    dateGenerated: "2023-02-16",
    isRedeemed: false,
    url: `${config.backendUrl}/admin/plumbers_exports`,
  },
  {
    id: 3,
    fileName: "CustomersList.xlsx",
    rewardPoints: 1200,
    dateGenerated: "2023-02-16",
    isRedeemed: false,
    url: `${config.backendUrl}/admin/customers_exports`,

  }
  

];
function Reports() {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); 
    const [searchTerm, setSearchTerm] = useState('');  
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };

    const handleDownload = (downloadUrl) => {
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'ExcelReport.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    const filteredItems = searchTerm ? data.filter(item =>
      item.fileName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.dateGenerated.includes(searchTerm) ||
      (item.isRedeemed ? 'redeemed' : 'not redeemed').toLowerCase().includes(searchTerm.toLowerCase())
    ) : data;

    const sortItems = (key) => {
      let direction = 'ascending';
      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };
  
    const sortedItems = filteredItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    // Use filteredItems instead of data for pagination
    const currentItems = filteredItems.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );

    const totalPages = Math.ceil(data.length / itemsPerPage);
    const renderPageNumbers = () => {
      let pages = [];
      for (let i = 0; i < totalPages; i++) {
          let isActive = currentPage === i;
        pages.push(
          <button
            key={i}
            style={{ margin: 5 }}
            onClick={() => handlePageChange(i)}
            className={isActive ? 'active' : ''}
            disabled={currentPage === i}
          >
            {i + 1}
          </button>
        );
      }
      return pages;
    };
  return (
    <> 
  
 
    <div className='flex justify-between items-center'> 
      <div> 
        <h2>Reports</h2>
        <div className='breadcrumbs'> <Link to="/admin/dashboard">Home</Link>{">"} Reports </div>
        </div>
        <div className='flex gap-2 items-center'> 
        <div>
        {/* <button className='genreport-btn'>Generate Report</button> */}
      </div> 
    <div className='flex table-search bg-white'>      
    <input
        type="text"
        placeholder="Search here..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <IoIosSearch />
    </div>
    <select onChange={(e) => sortItems(e.target.value)} className='sort-dropdown'>
  <option value="" disabled selected>Sort By</option>
  <option value="fileName">File Name</option>
  <option value="rewardPoints">Reward Points</option>
  <option value="dateGenerated">Date Generated</option>
  <option value="isRedeemed">Redemption Status</option>
</select>
    <div>
        
      </div> 
    
    </div>
</div>
    
    
    <div  className='whitebox'>
        


    <div>
      <table className='tablegrid' width={"100%"}>
        <thead>
          <tr>
            
            <th>SNo</th>
            <th>File Name</th>            
        
            <th> </th>
            
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
          <tr key={item.id}>
          <td>{index + 1 + currentPage * itemsPerPage}</td>
          <td>{item.fileName}</td> 
         
          <td className='text-right'> <button type="button"  onClick={() => handleDownload(item.url)}   className='aprove-btn' >Download Report</button></td>
        </tr>
          ))}
        </tbody>
      </table>

      <div className='flex justify-between'>
<div> </div>
      <div className='pagenation'> 
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 0} >
          {"<"}
        </button>
        
        {/* Page Numbers */}
        {renderPageNumbers()}
        
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages - 1}
        >
          {">"}
        </button>
        </div>
      </div>
    </div>

    </div>
    </>
  )
}

export default Reports