import React from 'react'

function FormBox({children}) {
  return (
  <div className='form-wrapper'>
    <div className='formbox'>
        
        {children}
         </div>
         </div>
  )
}

export default FormBox