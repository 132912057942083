import { ax } from "../index";

export default class UserService {
    
    userRegister(payload) {
        return ax.post('/users/registeration', payload);
    }
    userDetails() {
        return ax.post('/users/getUserDetails');
    }
    userDetailsUpdate(payload) {
        return ax.post('/users/user-update', payload);
    }
    rewardPoints() {
        return ax.post('/users/scanners/get');
    }
    rewardHistory(payload) {
        return ax.post('users/transactions/get', payload);
    }
    availablePoints() {
        return ax.post('/users/reedemed/store');
    }
    locationDetails(payload) {
        return ax.post('/users/maps', payload);
    }
    productScan(payload) {
        return ax.post('/users/scanner/store', payload);
    }
    productDetails(payload) {
        return ax.post('/users/products/get', payload);
    }
}
