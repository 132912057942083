import React,{useState,useEffect} from 'react'
import { Link  } from 'react-router-dom';
import { IoIosSearch } from "react-icons/io";
import Popover from '../admin/shared/Popover';  
import Preview from './shared/Preview';
import axios from "axios";
import config from "../config";
import { GENERATED_QRS, PRINT_PRODUCT_QR } from '../common/constants';
import Pagination  from "../common/Pagination";

function QrList() { 
  const [itemsPerPage, setItemsPerPage] = useState(10); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [PopoverprevId, setPopoverprevId] = useState(null);
    const [selectedPopoverId, setSelectedPopoverId] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'productName', direction: 'ascending' });
    const [qrList, setQrListData] = useState([]);
    const [selectedQr, setSelectedQr] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 10; // Number of items per page
    useEffect(() => {
      // Fetch data from the API
    
      getQrs();
      
    
    
    }, [currentPage, limit]); // Empty dependency array means this effect runs only once on component mount

    const getQrs=()=>{
      axios.post(GENERATED_QRS,{params: {
        page: currentPage,
      
      },}, {
        headers: {
          'token': localStorage.getItem('token'), // Example authorization header
          'Content-Type': 'application/json', // Example content type header
          // Add more headers as needed
        }
        })
      .then(response => {
        // Handle response
        setQrListData(response.data.data);
        setTotalPages(Math.ceil(response.data.total_count / limit));
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });
    }
    const handleOpenprevPopover = (id) => {
      setPopoverprevId(id);
    };
  
    const handleCloseprevPopover = () => {
      setPopoverprevId(null);
    };


    const handleOpenPopover = (qrListId) => {

      const foundQr = qrList.find(list => list.id === qrListId);

      if(foundQr)
      {
        setSelectedQr(foundQr);
        setSelectedPopoverId(true);

      }

    };
  
    const handleClosePopover = () => {
      setSelectedPopoverId(false);
    };
   
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
     const handlePrintQrs=(qrListId)=>{

     
      axios.post(PRINT_PRODUCT_QR,{'qr_list_id' : qrListId})
      .then(response => {
        // Handle response
     

       printImages(response.data.data);
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });
     }

     const printImages = (images) => {

    };
  const sortItems = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
 

    const filteredItems = searchTerm ? qrList.filter(item =>
      item.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||      
      item.dateCreated.includes(searchTerm) || 
      (item.isRedeemed ? 'Yes' : 'No').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.redeemedBy && item.redeemedBy.toLowerCase().includes(searchTerm.toLowerCase()))||
      item.rewardPoints.toString().includes(searchTerm)

    ) : qrList;

    const sortedFilteredItems = filteredItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  
    // Use filteredItems instead of data for pagination
 

    

    const ViewQr = (id)=>{

        const foundQr = qrList.find(list => list.id === id);

      if(foundQr)
      {
        setSelectedQr(foundQr);
        setSelectedPopoverId(true);

      }

    }
  return (
    <> 
  
 
    <div className='flex justify-between items-center'> 
      <div> 
        <h2> QR List</h2>
        <div className='breadcrumbs'> <Link to="/admin/dashboard">Home</Link>{">"} QR List </div>
      </div>
        <div className='flex gap-2 items-center'> 
        <div>
        <button className='genreport-btn'>Generate Report</button>
      </div> 
      <div className='flex gap-2 mob-wrap mob-alignfilters'> 
          <div className='flex table-search bg-white'>
          <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <IoIosSearch />
          </div>
        <select value={sortConfig.key} onChange={(e) => sortItems(e.target.value)} className='sort-dropdown'>
            <option value="" disabled selected>Sort By</option>
            <option value="productName">Product Name</option>
            <option value="rewardPoints">Reward Points</option>
            <option value="dateCreated">Date Created</option>
            <option value="isRedeemed">Redemption Status</option>
            <option value="redeemedBy">Redeemed By</option>
        </select>
        </div>
    <div>
        
      </div> 
    
    </div>
</div>
    
    
    <div  className='whitebox'>
        


    <div>
      <table className='tablegrid' width={"100%"}>
        <thead>
          <tr>
            
            <th>SNo</th>
            <th>Product Name</th>
            
            <th>Reward Points</th>
            <th>Is Scanned</th>
            <th>Date Created</th>
           
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {qrList.map((item, index) => (
           <tr key={index}>
           <td>{index + 1 + (currentPage - 1) * limit}</td>
           <td>{item.product_name}</td>
        
           <td>{item.reward_points}</td>
           <td>{(item.is_scanned)? "Yes" : "No"}</td>

           <td>{item.created_at}</td>
       
       
         
           <td className='redfont'><button    onClick={()=>ViewQr(item.id)} > View QR</button></td>
         </tr>
          ))}
        </tbody>
      </table>

    
   
      <div className="flex justify-between">
          <div> </div>
          <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
             />

        </div>
      </div>

    </div>

    {PopoverprevId && (
          <Popover onClose={handleCloseprevPopover} isVisible={setPopoverprevId !== null}>
            <Preview></Preview>
          </Popover>
        )}
    {selectedPopoverId && (
          <Popover onClose={handleClosePopover} isVisible={selectedPopoverId !== null}>
            <h4  style={{margin:"-20px 20px 10px 0px"}}>View QR</h4>
            <div style={{color:"#000"}} className='flex justify-between'> 
            <div className='pl-5'>
              <div>Product Name</div>
              <div style={{fontSize:"16px",marginBottom:"10px"}}>{selectedQr.product_name}</div>
              <div>Rewards</div>
              <div style={{fontSize:"16px",marginBottom:"10px"}}>{selectedQr.reward_points}</div>
            </div>

            <div className='pl-5'>
              <img src={selectedQr.imageUrl} alt='qrcpde' width="40%" />  

            </div>


            </div>

            {/* <div className='text-center mt-4'> <button style={{width:"100px"}} className='genreport-btn'>Edit</button></div> */}
     
          </Popover>
        )}
    
    </>
  )
}

export default QrList