import React, { useEffect, useState } from 'react';
import RewardsHeader from './shared/RewardsHeader';
import UserService from '../services/common/user.service';
import InfiniteScroll from 'react-infinite-scroll-component';

function RewardsHistory() {
  const userServiceInstance = new UserService();
  const [historyRewards, setHistoryRewards] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const fetchData = () => {
    let payload = { page }
    userServiceInstance.rewardHistory(payload).then(response => {
      if (response.data.statusCode === 200) {
        if (response?.data?.data.length > 0) {
          setHistoryRewards([...historyRewards, ...response.data.data]);
          setPage(page + 1);
        } else {
          setHasMore(false);
        }
      }
    }).catch(error => console.log(error))
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <>
      <RewardsHeader />
      <div className="form-wrapper" style={{ marginTop: "-85px" }}>
        <h4 className="whitefont mb-2">My Reward History</h4>
        <ul className='userrewardhistorylist'>
          <InfiniteScroll
            dataLength={historyRewards.length}
            next={fetchData}
            scrollThreshold="100px"
            hasMore={hasMore}
            loader={<p className='w-fit mx-auto py-5'>loading....</p>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }>
            {historyRewards.map((reward, i) => (
              <li key={i} className={reward.product_name === "Rewards Claimed" ? 'selectedlist' : ''}>
                <label>
                  {reward.product_name}
                  <div className='font9'>{reward.fromated_created_dt}</div>
                </label>
                <label className='redfont'>
                  {reward?.reward_points}
                </label>
              </li>
            ))}
          </InfiniteScroll>
        </ul>
      </div>
    </>
  )
}

export default RewardsHistory;
